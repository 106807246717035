export const vClickOutside = {
  mounted(el: HTMLElement, binding: any) {
    el.clickOutside = function (event: Event) {
      if (!(el === event.target || el.contains(event.target as Node))) binding.value();
    };
    document.body.addEventListener('mousedown', el.clickOutside);
  },
  unmounted(el: HTMLElement) {
    document.body.removeEventListener('mousedown', el.clickOutside);
  },
};
